<template>
  <div class="export-button-container">
    <el-button class="export-button" type="primary" icon="el-icon-download" size="mini" @click="exportMessages()">
      Eksportuj wiadomości zendesk
    </el-button>
    <el-dialog
      :visible="downloading"
      title="Przygotowywanie pliku"
      width="30%"
      :show-close="false"
    >
      <el-spinner v-if="downloading && !file" :fullscreen="false" :size="100" />
      <el-button v-if="file" @click="successHandler(file)" type="primary" icon="el-icon-download" size="mini">
        Zapisz plik
      </el-button>
    </el-dialog>
  </div>
</template>

<script>
import {ZendeskTask} from '@/api'
import {downloadBlobResponse} from "@/common/js/functions";

export default {
  data() {
    return {
      downloading: false,
      file: null,
    }
  },
  methods: {
    successHandler(response) {
      downloadBlobResponse(response)
      this.file = null
      this.downloading = false
    },
    exportMessages() {
      this.downloading = true
      this.file = null
      this.service.exportReplyMessages()
        .then(response => {
          this.file = response
        })
        .catch(error => {
          this.errorHandler(error)
          this.file = null
          this.downloading = false
        })
        .finally(() => {
        })
    }
  },
  computed: {
    service() {
      return ZendeskTask
    }
  }
}
</script>

<style scoped>
.export-button-container {
  width: 100%;
  height: 100%;
  display: flex;
}
.export-button {
  margin: auto;
  min-width: 250px;
  min-height: 75px;
}
</style>
